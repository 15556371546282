<template>
  <div class="exam-detail">
    <div class="bg"></div>
    <div class="container main">
      <div class="module1">
        <div class="ant-spin-nested-loading">
          <div class="ant-spin-container">
            <div class="playArea">
              <div class="left">
                <div class="water-mark">
                  <span></span><span></span>
                </div>
                <div class="video-player">
                  {{ videourl }}
                      <p>Current time: {{ currentTime }}</p>

                  <video ref="videoPlayer" preload="auto" autoplay="autoplay" controls style="width: 100%; height: 90%;" :src="videourl" @timeupdate="updateTime"></video>
                </div>
              </div>
              <div class="right">
                <ul>
                  <li v-for="(item,index) in videos" :key="index" @click="playVideo(item)" class="item">
                    <div class="icon"><i class="el-icon-video-play"></i></div>
                    <div class="videoInfo">
                      <div class="title">{{ index + 1 }}、{{ item.title }}</div>
                      <div class="bottom">视频
                        <div class="progress">进度：0%</div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="module2">
        <div class="course-detail">
          <div class="title">
            <div class="taskName">{{ detail.courseName }}</div>
            <div class="num">（1节课程）</div>
            <div class="proggress">总进度：<span>{{ detail.progress }}%</span></div>
          </div>
          <div class="rate">
            <div class="score">0.0</div>
            <div class="num">
              0人打分
              <el-rate v-model="value1" disabled ></el-rate>
            </div>
          </div>
          <div class="detail">
            <div class="tag">
              课程介绍
            </div>
            <div class="panel">
              <div class="content">
                <span>讲师：{{ teacher.name || '暂无讲师' }}</span>
                <span>学习时长：{{ syllabus.length }}分钟</span>
                <span>学习人数：{{ detail.learnUsers }}人</span>
                <span>收藏：{{ detail.starUsers }}人</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="module3">
        <div class="left">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="课程目录" name="first">
              <div class="directory">
                <div class="item" v-for="(item,index) in detail.details" :key="index">
                  <div class="sort">第{{ item.sort }}节</div>
                  <div class="info">
                    <p class="title"><i class="el-icon-video-play"></i>{{ item.fileName }}</p>
                    <div class="data"><span>视频</span> 时长：{{ item.times }} 进度：{{ item.progress }}%</div>
                  </div>
                  <div class="status"><i class="el-icon-check"></i>已学完</div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="学习情况" name="second">
              <div class="starInfo">学习人数 {{ learningList.length }}</div>
              <ul class="starbox">
                <li class="item" v-for="(item,index) in learningList" :key="index">
                  <img :src="item.avatar" alt="">
                  <div class="info">
                    <p class="name">{{ item.userName }}</p>
                    <p>{{ item.customerName }}</p>
                  </div>
                  <span class="progress">
                      {{ item.proggress }}%
                    </span>
                </li>
              </ul>
              <p class="noMore">没有更多了</p>
            </el-tab-pane>
            <el-tab-pane label="课程评论" name="third">
              <div class="Comment">
                <div class="top">
                  <label for="">课程评分：</label>
                  <el-rate v-model="value1"></el-rate>
                </div>
                <el-input
                    type="textarea"
                    rows='6'
                    placeholder="说点什么吧..."
                    v-model="textarea2">
                </el-input>
                <div class="btnbox">
                  <el-button type="primary" class="btn" :disabled='textarea2.length>0'>评论</el-button>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="right">
          <div class="card-head">
            <div class="card-head-title">推荐课程</div>
            <div class="card-extra">
              <i class="el-icon-refresh"></i>
              刷一刷
            </div>
          </div>
          <ul class="listbox">
            <li class="item" v-for="(item,index) in list" :key="index">
              <img :src="item.coverImg" alt="">
              <div class="info">
                <p class="title">{{ item.courseName }}</p>
                <p class="num">学习人数：{{ item.learnUsers }} 人</p>
                <p class="jiangshi">讲师：{{ item.lecturers || '-' }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import empty from "@/assets/image/no_audition.png"
import {getSyllabusDetail} from "@/api/syllabus/index";

export default {
  data() {
    return {
      videos: [],
      value1:null,
      empty: empty,
      activeName: 'first',
      videourl: 'http://vjs.zencdn.net/v/oceans.mp4',
      list: [
        {
          "courseId": 201507,
          "courseName": "瞬间大变样的秘密：Word模板",
          "createTime": 1685926570,
          "coverImg": "//data.51-learning.com/data1/121/cover/a32a1df8-e1ce-4e9c-a7a4-02556f43fc52.png",
          "folderId": 19611,
          "folderName": "Office操作",
          "folderPath": "Office操作",
          "intro": "",
          "serialCode": "OF2007",
          "labels": "",
          "lecturers": null,
          "courseSize": 17486392,
          "coursePeriod": 486,
          "learnCount": 0,
          "learnUsers": 0,
          "favorites": 0,
          "starScore": 0,
          "starUsers": 0,
          "comments": 0,
          "startTime": 0,
          "lastTime": 0,
          "learnTime": 0,
          "sectionTypes": [
            1
          ],
          "learnOrder": 0,
          "draggable": 1,
          "multiplePlay": 1,
          "openFace": 4,
          "preventOnhook": 0,
          "syncData": 0,
          "progress": 0,
          "secretData": 0,
          "courseType": 1,
          "allowMobile": 1,
          "providerType": 0,
          "outSourceId": "",
          "outSourceName": "",
          "introImgs": "",
          "price": 0,
          "isIntention": false,
          "sourceId": 0,
          "isSource": 0,
          "myStar": 0
        },
        {
          "courseId": 201506,
          "courseName": "Word中的精华与核心：样式",
          "createTime": 1685926570,
          "coverImg": "//data.51-learning.com/data1/121/cover/9c7cd7b8-1fca-4b0b-a09e-9d5ae709f57b.png",
          "folderId": 19611,
          "folderName": "Office操作",
          "folderPath": "Office操作",
          "intro": "",
          "serialCode": "OF2006",
          "labels": "",
          "lecturers": null,
          "courseSize": 101371857,
          "coursePeriod": 2618,
          "learnCount": 0,
          "learnUsers": 0,
          "favorites": 0,
          "starScore": 0,
          "starUsers": 0,
          "comments": 0,
          "startTime": 0,
          "lastTime": 0,
          "learnTime": 0,
          "sectionTypes": [
            1
          ],
          "learnOrder": 0,
          "draggable": 1,
          "multiplePlay": 1,
          "openFace": 4,
          "preventOnhook": 0,
          "syncData": 0,
          "progress": 0,
          "secretData": 0,
          "courseType": 1,
          "allowMobile": 1,
          "providerType": 0,
          "outSourceId": "",
          "outSourceName": "",
          "introImgs": "",
          "price": 0,
          "isIntention": false,
          "sourceId": 0,
          "isSource": 0,
          "myStar": 0
        },
        {
          "courseId": 201505,
          "courseName": "Word表格我做主：插入与排版",
          "createTime": 1685926570,
          "coverImg": "//data.51-learning.com/data1/121/cover/0c9d00c0-fd22-4870-b2a9-501b4e0d35a6.png",
          "folderId": 19611,
          "folderName": "Office操作",
          "folderPath": "Office操作",
          "intro": "",
          "serialCode": "OF2005",
          "labels": "",
          "lecturers": null,
          "courseSize": 96914382,
          "coursePeriod": 2878,
          "learnCount": 0,
          "learnUsers": 0,
          "favorites": 0,
          "starScore": 0,
          "starUsers": 0,
          "comments": 0,
          "startTime": 0,
          "lastTime": 0,
          "learnTime": 0,
          "sectionTypes": [
            1
          ],
          "learnOrder": 0,
          "draggable": 1,
          "multiplePlay": 1,
          "openFace": 4,
          "preventOnhook": 0,
          "syncData": 0,
          "progress": 0,
          "secretData": 0,
          "courseType": 1,
          "allowMobile": 1,
          "providerType": 0,
          "outSourceId": "",
          "outSourceName": "",
          "introImgs": "",
          "price": 0,
          "isIntention": false,
          "sourceId": 0,
          "isSource": 0,
          "myStar": 0
        },
        {
          "courseId": 201504,
          "courseName": "图片与文字的亲密接触：图文混排",
          "createTime": 1685926570,
          "coverImg": "//data.51-learning.com/data1/121/cover/5e3e3aa9-0265-4c30-83ce-2b88fe8edb08.png",
          "folderId": 19611,
          "folderName": "Office操作",
          "folderPath": "Office操作",
          "intro": "",
          "serialCode": "OF2004",
          "labels": "",
          "lecturers": null,
          "courseSize": 101082749,
          "coursePeriod": 2708,
          "learnCount": 0,
          "learnUsers": 0,
          "favorites": 0,
          "starScore": 0,
          "starUsers": 0,
          "comments": 0,
          "startTime": 0,
          "lastTime": 0,
          "learnTime": 0,
          "sectionTypes": [
            1
          ],
          "learnOrder": 0,
          "draggable": 1,
          "multiplePlay": 1,
          "openFace": 4,
          "preventOnhook": 0,
          "syncData": 0,
          "progress": 0,
          "secretData": 0,
          "courseType": 1,
          "allowMobile": 1,
          "providerType": 0,
          "outSourceId": "",
          "outSourceName": "",
          "introImgs": "",
          "price": 0,
          "isIntention": false,
          "sourceId": 0,
          "isSource": 0,
          "myStar": 0
        },
        {
          "courseId": 201503,
          "courseName": "为你的文档做个精装修：边框与底纹",
          "createTime": 1685926570,
          "coverImg": "//data.51-learning.com/data1/121/cover/47bc42ff-d012-419f-97ff-ddf7e64da4e9.png",
          "folderId": 19611,
          "folderName": "Office操作",
          "folderPath": "Office操作",
          "intro": "",
          "serialCode": "OF2003",
          "labels": "",
          "lecturers": null,
          "courseSize": 37629321,
          "coursePeriod": 835,
          "learnCount": 0,
          "learnUsers": 0,
          "favorites": 0,
          "starScore": 0,
          "starUsers": 0,
          "comments": 0,
          "startTime": 0,
          "lastTime": 0,
          "learnTime": 0,
          "sectionTypes": [
            1
          ],
          "learnOrder": 0,
          "draggable": 1,
          "multiplePlay": 1,
          "openFace": 4,
          "preventOnhook": 0,
          "syncData": 0,
          "progress": 0,
          "secretData": 0,
          "courseType": 1,
          "allowMobile": 1,
          "providerType": 0,
          "outSourceId": "",
          "outSourceName": "",
          "introImgs": "",
          "price": 0,
          "isIntention": false,
          "sourceId": 0,
          "isSource": 0,
          "myStar": 0
        },
        {
          "courseId": 201502,
          "courseName": "让你的Word与众不同：快速输入与美化",
          "createTime": 1685926570,
          "coverImg": "//data.51-learning.com/data1/121/cover/0fec32b9-e95b-4f99-bcf5-a5d0675f7f17.png",
          "folderId": 19611,
          "folderName": "Office操作",
          "folderPath": "Office操作",
          "intro": "",
          "serialCode": "OF2002",
          "labels": "",
          "lecturers": null,
          "courseSize": 114245569,
          "coursePeriod": 3414,
          "learnCount": 0,
          "learnUsers": 0,
          "favorites": 0,
          "starScore": 0,
          "starUsers": 0,
          "comments": 0,
          "startTime": 0,
          "lastTime": 0,
          "learnTime": 0,
          "sectionTypes": [
            1
          ],
          "learnOrder": 0,
          "draggable": 1,
          "multiplePlay": 1,
          "openFace": 4,
          "preventOnhook": 0,
          "syncData": 0,
          "progress": 0,
          "secretData": 0,
          "courseType": 1,
          "allowMobile": 1,
          "providerType": 0,
          "outSourceId": "",
          "outSourceName": "",
          "introImgs": "",
          "price": 0,
          "isIntention": false,
          "sourceId": 0,
          "isSource": 0,
          "myStar": 0
        },
        {
          "courseId": 201501,
          "courseName": "让你的Word做做运动：基础设置",
          "createTime": 1685926570,
          "coverImg": "//data.51-learning.com/data1/121/cover/55dd69a7-f72c-464b-a900-2779c65ac89d.png",
          "folderId": 19611,
          "folderName": "Office操作",
          "folderPath": "Office操作",
          "intro": "",
          "serialCode": "OF2001",
          "labels": "",
          "lecturers": null,
          "courseSize": 24043874,
          "coursePeriod": 725,
          "learnCount": 0,
          "learnUsers": 0,
          "favorites": 0,
          "starScore": 0,
          "starUsers": 0,
          "comments": 0,
          "startTime": 0,
          "lastTime": 0,
          "learnTime": 0,
          "sectionTypes": [
            1
          ],
          "learnOrder": 0,
          "draggable": 1,
          "multiplePlay": 1,
          "openFace": 4,
          "preventOnhook": 0,
          "syncData": 0,
          "progress": 0,
          "secretData": 0,
          "courseType": 1,
          "allowMobile": 1,
          "providerType": 0,
          "outSourceId": "",
          "outSourceName": "",
          "introImgs": "",
          "price": 0,
          "isIntention": false,
          "sourceId": 0,
          "isSource": 0,
          "myStar": 0
        },
      ],
      learningList: [
        {
          avatar: "//data.51-learning.com/data1/121/cover/a32a1df8-e1ce-4e9c-a7a4-02556f43fc52.png",
          userName: '逍遥',
          customerName: "学创联盟",
          proggress: 100
        }
      ],
      detail: {
        "courseId": 201511,
        "courseName": "TRAINGO介绍",
        "createTime": 1685926570,
        "coverImg": "//data.51-learning.com/data1/121/cover/157fb266-a9d6-47ef-869f-80228d1c1f14.png",
        "folderId": 19613,
        "folderName": "平台介绍",
        "folderPath": "/19613/",
        "intro": "",
        "serialCode": "TG0001",
        "labels": "测试标签1",
        "lecturers": [],
        "courseSize": 74911909,
        "coursePeriod": 259,
        "learnCount": 5,
        "learnUsers": 1,
        "favorites": 1,
        "starScore": 0,
        "starUsers": 0,
        "comments": 0,
        "startTime": 0,
        "lastTime": 1687134676,
        "learnTime": 0,
        "sectionTypes": null,
        "learnOrder": 2,
        "draggable": 1,
        "multiplePlay": 1,
        "openFace": 4,
        "preventOnhook": -1,
        "syncData": 0,
        "progress": 100,
        "secretData": 0,
        "courseType": 1,
        "allowMobile": 1,
        "providerType": 0,
        "outSourceId": "",
        "outSourceName": "",
        "introImgs": "",
        "price": 0,
        "sourceId": 0,
        "isSource": 0,
        "isFavorite": false,
        "isIntention": false,
        "myStar": 0,
        "documentTimes": 0,
        "details": [
          {
            "chapterId": 0,
            "documentId": 287418,
            "filePath": "https://wxwork.51-learning.com/5ea9db5e-9130-4c72-89f0-658ca4ae8eac",
            "fileName": "TRAINGO介绍",
            "home": "",
            "fileSize": 74911909,
            "sourceId": 0,
            "sourcePath": "",
            "sourceName": "",
            "sourceSize": 0,
            "sourceType": 0,
            "times": '04:19',
            "pages": 0,
            "suffix": "mp4",
            "width": 1280,
            "height": 720,
            "fileType": 1,
            "status": 1,
            "encrypted": 1,
            "quality": 0,
            "secretData": 0,
            "outFileId": "",
            "videoId": "",
            "sort": 1,
            "scorm": null,
            "progress": 100,
            "isFavorite": false,
            "isLike": false,
            "syncData": 0,
            "lastTime": 1687134676,
            "location": 110,
            "preventOnhook": 0,
            "documentTimes": 0
          }
        ]
      },
      textarea2: '',
      syllabus: {},
      teacher: {},
      id: 0,
      currentTime:0,
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getData();
  },
  methods: {
    updateTime() {
      this.currentTime = this.$refs.videoPlayer.currentTime;
    },
    playVideo(item){
      console.log(item);
      this.videourl = item.file_url;
    },
    getData() {
      var _this = this;
      getSyllabusDetail({
        id: _this.id,
      }).then(res => {
        console.log( res.data.videos[0].file_url,' _this.videos[0].file_url;');
        if (res.code == 200) {
          _this.videos = res.data.videos;
          // _this.videourl = res.data.videos[0].file_url;
          _this.syllabus = res.data.syllabus;
          _this.teacher = res.data.teacher;

        }
      })
    },
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>
<style lang='scss' scoped>
.exam-detail {
  .bg {
    width: 100%;
    height: 300px;
    background: linear-gradient(90deg, #272f46, rgba(39, 47, 70, .8));;
  }

  .main {
    position: relative;
    left: 50%;
    margin-left: -600px;
    top: -260px;

    .module1 {
      width: 100%;
      background-color: #fff;
      border-radius: 6px;
      box-sizing: border-box;

      .ant-spin-nested-loading {
        position: relative;

        .ant-spin-container {
          position: relative;
          transition: opacity .3s;

          .playArea {
            height: 562px;
            display: flex;

            .left {
              position: relative;
              width: 890px;
              height: 100%;
              background: #090909;
              text-align: center;

              .video-player {
                width: 100%;
                height: 100%;
              }
            }

            .right {
              width: 310px;
              height: 100%;
              background: #2d2d2d;
              border-right: 1px solid #2d2d2d;
              border-top: 1px solid #2d2d2d;
              border-bottom: 1px solid #2d2d2d;

              .item {
                background: #1e1e1e;
                display: flex;
                padding: 14px;
                box-sizing: border-box;
                .videoInfo{
                  width: 230px;
                }
                .icon {
                  color: #999;
                  font-size: 18px;
                  width: 40px;
                  padding-top: 2px;
                }

                .title {
                  color: #999;
                  font-size: 16px;
                  margin: 0 0 8px 0;
                  font-weight: 400;
                  line-height: 22px;
                  min-height: 22px;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  -o-text-overflow: ellipsis;
                  overflow: hidden;
                }

                .bottom {
                  display: flex;
                  color: #666;
                  font-size: 12px;
                  align-content: center;

                  .progress {
                    margin-left: 40px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .module2 {
      margin-top: 20px;
      width: 100%;
      background-color: #fff;
      padding: 20px 28px;
      border-radius: 6px;
      box-sizing: border-box;

      .course-detail {
        background: #fff;
        border-radius: 4px;
        padding: 30px;
        width: 100%;
        margin: 0 auto;
        box-sizing: border-box;

        .title {
          height: 30px;
          line-height: 30px;
          margin-bottom: 20px;

          .taskName {
            line-height: 30px;
            min-height: 30px;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            overflow: hidden;
            font-size: 30px;
            color: #202020;
            max-width: 70%;
            display: -webkit-inline-box;
            float: left;
          }

          .num {
            font-size: 18px;
            color: #999;
            float: left;
          }

          .proggress {
            font-size: 18px;
            font-weight: 700;
            color: #202020;
            float: right;

            span {
              color: #286eff;
              font-weight: 600;
            }
          }
        }

        .rate {
          display: flex;
          margin-bottom: 26px;

          .score {
            font-size: 30px;
            color: #f5961b;
            font-weight: 700;
            margin-right: 15px;
            line-height: 1.15;
          }

          .num {
            margin: -6px 0 -4px;
            color: #999;
            font-size: 14px;
          }
        }

        .detail {
          .tag {
            width: 94px;
            padding: 6px 10px;
            line-height: 1.1225;
            color: #266fff;
            border: 1px solid #266fff;
            border-radius: 2px;
            cursor: pointer;
            display: initial;
          }

          .panel {
            padding: 30px 70px 0;
            box-sizing: border-box;

            .content {
              font-size: 16px;
              color: #333;

              span {
                margin-right: 50px;
              }
            }
          }
        }
      }
    }

    .module3 {
      display: flex;
      margin-top: 20px;

      .left {
        float: left;
        width: 890px;
        min-height: 627px;
        padding: 10px 30px 30px;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 30px;

        .starInfo {
          color: rgba(0, 0, 0, .85);
          font-size: 14px;
        }

        .noMore {
          text-align: center;
          margin-top: 30px;
          height: 32px;
          font-size: 14px;
          line-height: 32px;
          color: rgb(188, 188, 188);
        }

        .directory {
          .item {
            display: flex;
            cursor: pointer;
            background: #f5f5f6;
            padding: 20px;
            box-sizing: border-box;

            .sort {
              margin-bottom: 14px;
              color: #202020;
              font-size: 16px;
              margin-right: 20px;
            }

            .info {
              flex: 1;

              .title {
                color: rgba(0, 0, 0, .85);
                font-weight: 500;
                font-size: 16px;

                i {
                  margin-right: 10px;
                }
              }

              .data {
                color: #999;
                font-size: 14px;
                margin-top: 16px;
              }
            }

            .status {
              color: #1ab700;
              font-size: 16px;

              i {
                font-weight: 600;
                font-size: 16px;
                margin-right: 6px;
              }
            }
          }
        }

        .starbox {

          .item {
            display: flex;
            padding: 10px 0;
            border-bottom: 1px solid #f0f0f0;

            img {
              display: block;
              width: 45px;
              height: 45px;
              border-radius: 50%;
              object-fit: cover;
              margin-right: 10px;
            }

            .info {
              flex: 1;
              color: rgba(0, 0, 0, .45);
              font-size: 14px;
              line-height: 1.5715;

              .name {
                color: rgba(0, 0, 0, .85);
                font-size: 14px;
                line-height: 1.5715;
              }
            }

            .progress {
              color: rgb(26, 183, 0);
            }
          }
        }

        .Comment {
          .top {
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            label {
              font-size: 16px;
              font-weight: 600;
              color: #333;
            }
          }

          .btnbox {
            display: flex;
            justify-content: end;
            margin-top: 20px;

            .btn {
              width: 64px;
              height: 32px;
              // background: #266fff ;
            }
          }
        }
      }

      .right {
        float: right;
        width: 290px;
        background: #fff;
        border-radius: 4px;
        margin-left: 18px;

        .card-head {
          min-height: 48px;
          margin-bottom: -1px;
          padding: 0 24px;
          color: rgba(0, 0, 0, .85);
          font-weight: 500;
          font-size: 16px;
          background: transparent;
          border-bottom: 1px solid #f0f0f0;
          border-radius: 2px 2px 0 0;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .card-head-title {

          }

          .card-extra {
            margin-left: auto;
            padding: 16px 0;
            color: rgba(0, 0, 0, .85);
            font-weight: 400;
            font-size: 14px;
            color: #999;
          }
        }

        .listbox {
          padding: 24px;
          box-sizing: border-box;

          .item {
            display: flex;
            margin-bottom: 20px;

            img {
              width: 120px;
              height: 70px;
              border-radius: 4px;
            }

            .info {
              margin-left: 10px;

              .title {
                width: 130px;
                color: #333;
                line-height: 12px;
                min-height: 12px;
                white-space: nowrap;
                text-overflow: ellipsis;
                -o-text-overflow: ellipsis;
                overflow: hidden;
                margin-bottom: 22px;
                font-size: 12px;
              }

              .num {
                font-size: 12px;
                color: #999;
                margin: 0;
              }

              .jiangshi {
                font-size: 12px;
                color: #999;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>
